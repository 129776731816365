import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { useConfiguration } from '../../context/configurationContext';
import { Footer, H3, LayoutSingleColumn, Page, AsideContainerColumn } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './DashboardPage.module.css';
import { ensureCurrentUser } from '../../util/data';
import ProfileCompletionStatus from '../ProfileSettingsPage/ProfileCompletionStatus/ProfileCompletionStatus';
import { propTypes } from '../../util/types';
import TitleIcon from './images/title_icon.png';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import LimitedDashboardConsultant from './sections/LimitedDashboardConsultant/LimitedDashboardConsultant';
import { getIsPendingVerification, getIsClient, getIsConsultant, getIsVettedUser } from '../../util/user';
import LimitedDashboardClient from './sections/LimitedDashboardConsultant/LimitedDashboardClient';
import DashboardClient from './sections/LimitedDashboardConsultant/DashboardClient';
import DashboardConsultant from './sections/LimitedDashboardConsultant/DashboardConsultant';

export const DashboardPageComponent = props => {
  const { intl, scrollingDisabled, currentUser } = props;
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const user = ensureCurrentUser(currentUser);

  const config = useConfiguration();
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'DashboardPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({ id: 'DashboardPage.schemaDescription' });

  const isPendingVerification = getIsPendingVerification(user);
  const isClient = getIsClient(user);
  const isConsultant = getIsConsultant(user);
  const isVetted = getIsVettedUser(user);

  return (
    <Page
      className={css.root}
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.rootBackground}
        topbar={<TopbarContainer isDark={true} currentPage="DashboardPage" />}
        footer={<Footer />}
      >
        <div className={css.headingContainer}>
          <img className={css.headingIcon} src={TitleIcon} />
          <H3 className={css.heading}>
            <FormattedMessage id="DashboardPage.dashboard" />
          </H3>
        </div>

        <div className={css.horizontalWrapper}>
          <AsideContainerColumn>
            <ProfileCompletionStatus currentUser={user} />
          </AsideContainerColumn>

          {isClient && !isVetted && (
            <LimitedDashboardClient
              history={history}
              routeConfiguration={routeConfiguration}
              currentUser={user}
              isPendingVerification={isPendingVerification}
            />
          )}

          {isClient && isVetted && (
            <DashboardClient
              history={history}
              routeConfiguration={routeConfiguration}
              currentUser={user}
            />
          )}

          {isConsultant && !isVetted && (
            <LimitedDashboardConsultant
              history={history}
              routeConfiguration={routeConfiguration}
              currentUser={user}
              isPendingVerification={isPendingVerification}
            />
          )}

          {isConsultant && isVetted && (
            <DashboardConsultant
              history={history}
              routeConfiguration={routeConfiguration}
              currentUser={user}
            />
          )}

        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

DashboardPageComponent.defaultProps = {
  currentUser: null,
};

DashboardPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const DashboardPage = compose(
  connect(mapStateToProps),
  injectIntl
)(DashboardPageComponent);

export default DashboardPage;
