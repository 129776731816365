import React, { useMemo } from 'react';

import { FormattedMessage } from '../../../../util/reactIntl';

import { Button } from '../../../../components';
import css from '../../DashboardPage.module.css';
import { createResourceLocatorString } from '../../../../util/routes';
import { getUserStatus } from '../../../../util/user';
import { calculatePercentages } from '../../../ProfileSettingsPage/utils/profileCompletion';

const LimitedDashboardClient = (props) => {

    const { history, routeConfiguration, currentUser, isPendingVerification } = props;

    const profilePercentage = useMemo(() => {
        const calculatedProgress = calculatePercentages(currentUser);
        const sumOfPercentages = calculatedProgress.reduce((acc, step) => acc + step.progress, 0);
        return Math.round(sumOfPercentages / calculatedProgress.length);

    }, [currentUser]);



    return (
        <div className={css.roundedContainer}>

            {isPendingVerification ?
                <p className={css.info}>
                    <FormattedMessage id="DashboardPage.clientInfo.pendingVerification" />
                </p>
                :
                <>
                    <p className={css.title}>
                        <FormattedMessage id="DashboardPage.clientInfo.incompleteProfile.title" />
                    </p>
                    <p className={css.info}>
                        <FormattedMessage id="DashboardPage.clientInfo.incompleteProfile" />
                    </p>
                </>
            }

            <div className={css.circlesWrapper}>
                <div className={css.circle}>
                    1.
                </div>

                <div className={profilePercentage === 100 ? css.progressDone : css.progressActive} />

                <div className={css.circle}>
                    2.
                </div>
                <div className={css.progress} />

                <div className={css.circle}>
                    3.
                </div>
                <div className={css.progress} />

                <div className={css.circle}>
                    4.
                </div>
            </div>

            <div className={css.stepsWrapper}>
                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.completeProfile" />
                </span>

                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.createGig" />
                </span>

                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.browseFreelancers" />
                </span>

                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.appointFreelancer" />
                </span>
            </div>

            <div className={css.separator} />

            <div className={css.buttonWrapper}>

                <Button
                    className={css.button}
                    onClick={e => {
                        e.preventDefault();
                        history.push(createResourceLocatorString('ProfileSettingsPage', routeConfiguration, {}, {}));
                    }}
                >
                    <FormattedMessage id="DashboardPage.viewProfile" />
                </Button>
            </div>
        </div>
    )
}
export default LimitedDashboardClient