import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import css from './SectionMain.module.css';
import { FormattedMessage } from 'react-intl';
import { Button, NamedLink } from '../../../../components';

const SectionMain = props => {
  return (
    <div className={css.root}>
      <div className={css.semitransparentWrapper}>
        <p className={css.title}>
          <FormattedMessage id="SectionMain.title1" />

          <span className={css.subtitle}>
            <FormattedMessage id="SectionMain.title2" />
          </span>
          <br />

          <span className={css.subtitle}>
            <FormattedMessage id="SectionMain.title3" />
          </span>
          <FormattedMessage id="SectionMain.title4" />
          <br />

          <FormattedMessage id="SectionMain.title5" />
        </p>

        <p className={css.info}>
          <FormattedMessage id="SectionMain.info" />
        </p>

        <NamedLink name="SignupPage" className={css.button}>
          <Button>
            <FormattedMessage id="SectionMain.getStarted" />
          </Button>
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionMain;
