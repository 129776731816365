import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import PageBuilder from '../../containers/PageBuilder/PageBuilder';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { Footer, LayoutSingleColumn, Page } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './LandingPage.module.css';
import SectionHero from '../ListingPage/SectionHero';
import SectionContainer from '../PageBuilder/SectionBuilder/SectionContainer';
import SectionMain from './sections/SectionMain/SectionMain';
import SectionAbout from './sections/SectionAbout/SectionAbout';
import SectionInfo from './sections/SectionInfo/SectionInfo';
import SectionTestimonials from './sections/SectionTestimonials/SectionTestimonials';
import SectionSignUp from './sections/SectionSignUp/SectionSignUp';
import SectionHowItWorks from './sections/SectionHowItWorks/SectionHowItWorks';

export const LandingPageComponent = props => {
  const { intl, scrollingDisabled, isAuthenticated } = props;

  const config = useConfiguration();
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const currentPage = 'LandingPage';

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer isDark={true} currentPage={currentPage} />}
        footer={<Footer isAuthenticated={isAuthenticated} currentPage={currentPage} />}
      >
        <SectionMain />
        <SectionAbout />
        <SectionInfo />
        <SectionHowItWorks />
        {/* <SectionTestimonials /> */}
        <SectionSignUp />
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  isAuthenticated: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
