import React from 'react';

import css from './SectionAbout.module.css';
import { FormattedMessage } from 'react-intl';
import { Button, NamedLink } from '../../../../components';
import checkboxIcon from './images/checkbox.png';

const SectionAbout = props => {
  return (
    <div className={css.root}>
      <p className={css.subtitle}>
        <FormattedMessage id="SectionAbout.subtitle" />
      </p>

      <p className={css.title}>
        <FormattedMessage id="SectionAbout.title" />
      </p>

      <p className={css.info}>
        <FormattedMessage id="SectionAbout.info1" />

        <span className={css.infoHighlight}>
          <FormattedMessage id="SectionAbout.info2" />
        </span>

        <FormattedMessage id="SectionAbout.info3" />

        <span className={css.infoHighlight}>
          <FormattedMessage id="SectionAbout.info4" />
        </span>

        <FormattedMessage id="SectionAbout.info5" />
      </p>

      <div className={css.semitransparentWrapper}>
        <div>
          <p className={css.subtitle}>
            <FormattedMessage id="SectionAbout.panel.subtitle" />
          </p>

          <p className={css.title}>
            <FormattedMessage id="SectionAbout.panel.title" />
          </p>
        </div>

        <div>
          <div className={css.horizontalWrapper}>
            <img src={checkboxIcon} className={css.checkbox} />
            <p className={css.benefit}>
              <FormattedMessage id="SectionAbout.panel.benefit1" />
            </p>
          </div>

          <div className={css.horizontalWrapper}>
            <img src={checkboxIcon} className={css.checkbox} />
            <p className={css.benefit}>
              <FormattedMessage id="SectionAbout.panel.benefit2" />
            </p>
          </div>

          <div className={css.horizontalWrapper}>
            <img src={checkboxIcon} className={css.checkbox} />
            <p className={css.benefit}>
              <FormattedMessage id="SectionAbout.panel.benefit3" />
            </p>
          </div>
        </div>

        <div>
          <div className={css.horizontalWrapper}>
            <img src={checkboxIcon} className={css.checkbox} />
            <p className={css.benefit}>
              <FormattedMessage id="SectionAbout.panel.benefit4" />
            </p>
          </div>

          <div className={css.horizontalWrapper}>
            <img src={checkboxIcon} className={css.checkbox} />
            <p className={css.benefit}>
              <FormattedMessage id="SectionAbout.panel.benefit5" />
            </p>
          </div>

          <div className={css.marginTop}>
            <NamedLink name="SignupPage" className={css.signUp}>
              <Button className="buttonHighlighted">
                <FormattedMessage id="SectionAbout.panel.signUp" />
              </Button>
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAbout;
