import React, { useEffect, useRef } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import TitleIcon from './images/title_icon.png';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  propTypes,
  DATE_TYPE_DATE,
  DATE_TYPE_DATETIME,
  LINE_ITEM_NIGHT,
  LINE_ITEM_HOUR,
  LISTING_UNIT_TYPES,
} from '../../util/types';
import { formatDateWithProximity, subtractTime } from '../../util/dates';
import {
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  resolveLatestProcessName,
  getProcess,
  isBookingProcess,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import {
  Avatar, Page,
  PaginationLinks, Footer,
  IconSpinner,
  TimeRange,
  UserDisplayName, LayoutSingleColumn,
  H3
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { stateDataShape, getStateData } from './MessengerPage.stateData';
import css from './MessengerPage.module.css';
import { fetchMoreMessages } from '../TransactionPage/TransactionPage.duck';
import { fetchMessages, sendMessage } from './MessengerPage.duck';
import SendMessageForm from './SendMessageForm/SendMessageForm';
import { getIsClient } from '../../util/user';

export const UserItem = props => {
  const { transactionRole, tx, intl, stateData, isBooking, stockType = 'multipleItems', onShowMessages, config, isSelected } = props;
  const { customer, provider, listing } = tx;
  const { processName, processState, actionNeeded, isSaleNotification, isFinal } = stateData;
  const isCustomer = transactionRole === TX_TRANSITION_ACTOR_CUSTOMER;

  const otherUser = isCustomer ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;

  const fetchMessagesForTransaction = (txId) => {
    onShowMessages(txId, 1, config)
  }

  return (
    <div className={css.item}
      onClick={() => fetchMessagesForTransaction(tx.id.uuid)}>
      <div className={isSelected ? css.itemAvatarSelected : css.itemAvatar}>
        <Avatar user={otherUser} disableProfileLink={isCustomer} />
      </div>
      <div
      >
        {/* <div className={css.rowNotificationDot}>{rowNotificationDot}</div> */}
        <div className={css.lightText}>{otherUserDisplayName}</div>
        <div className={css.lightSmallText}>{listing?.attributes?.title}</div>
      </div>
    </div >
  );
};

UserItem.propTypes = {
  transactionRole: oneOf([TX_TRANSITION_ACTOR_CUSTOMER, TX_TRANSITION_ACTOR_PROVIDER]).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  stateData: stateDataShape.isRequired,
};


const Message = props => {
  const { message, formattedDate } = props;
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} />
      <div>
        <p className={css.messageContent}>{message.attributes.content}</p>
        <p className={css.messageDate}>{formattedDate}</p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  formattedDate: string.isRequired,
};

const OwnMessage = props => {

  const { message, formattedDate } = props;
  return (

    <div className={css.ownMessage}>

      <div>
        <div className={css.ownMessageContentWrapper}>
          <p className={css.ownMessageContent}>{message.attributes.content}</p>
        </div>
        <p className={css.ownMessageDate}>{formattedDate}</p>
      </div>

      <Avatar className={css.avatar} user={message.sender} />
    </div >
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  formattedDate: string.isRequired,
};


export const MessengerPageComponent = props => {
  const config = useConfiguration();
  const {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    onShowMessages,
    currentTransactionId,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    onSendMessage,
    messages,
    sendMessageInProgress,
    sendMessageError,
  } = props;

  const messagesListRef = useRef(null);
  useEffect(() => {
    messagesListRef.current.scrollTo({
      top: messagesListRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [messages])

  const hasNoResults = !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError;
  const hasNoMessage = !fetchInProgress && messages.length === 0 && !fetchOrdersOrSalesError;
  const title = intl.formatMessage({ id: 'MessengerPage.title' });

  const pickType = lt => conf => conf.listingType === lt;
  const findListingTypeConfig = publicData => {
    const listingTypeConfigs = config.listing?.listingTypes;
    const { listingType } = publicData || {};
    const foundConfig = listingTypeConfigs?.find(pickType(listingType));
    return foundConfig;
  };

  const isClient = getIsClient(currentUser);

  const toChatItem = tx => {
    const transactionRole = isClient ? TX_TRANSITION_ACTOR_PROVIDER : TX_TRANSITION_ACTOR_CUSTOMER;
    let stateData = null;
    try {
      stateData = getStateData({ transaction: tx, transactionRole, intl });
    } catch (error) {
      // If stateData is missing, omit the transaction from UserItem list.
    }

    const publicData = tx?.listing?.attributes?.publicData || {};
    const foundListingTypeConfig = findListingTypeConfig(publicData);
    const { transactionType, stockType } = foundListingTypeConfig || {};
    const process = tx?.attributes?.processName || transactionType?.transactionType;
    const transactionProcess = resolveLatestProcessName(process);
    const isBooking = isBookingProcess(transactionProcess);

    const isSelected = currentTransactionId === tx?.id.uuid;
    // Render UserItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <UserItem
          transactionRole={transactionRole}
          tx={tx}
          intl={intl}
          stateData={stateData}
          stockType={stockType}
          isBooking={isBooking}
          onShowMessages={onShowMessages}
          isSelected={isSelected}
          config={config}
        />
      </li>
    ) : null;
  };
  const todayString = intl.formatMessage({ id: 'TransactionPage.ActivityFeed.today' });

  const toTxMessageItem = message => {
    const formattedDate = formatDateWithProximity(message.attributes.createdAt, intl, todayString);
    const isOwnMessage = currentUser?.id && message?.sender?.id?.uuid === currentUser.id?.uuid;
    const messageComponent = isOwnMessage ? (
      <OwnMessage message={message} formattedDate={formattedDate} />
    ) : (
      <Message message={message} formattedDate={formattedDate} />
    );

    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent}
      </li>
    );

  };

  const hasOrderOrSaleTransactions = (tx, isClient, user) => {
    return isClient
      ? user?.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user?.id?.uuid
      : user?.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user?.id?.uuid;

  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isClient, currentUser);


  const onMessageSubmit = (values, form) => {
    const messagesList = messagesListRef.current;
    const previousScrollHeight = messagesList.scrollHeight;
    const previousScrollTop = messagesList.scrollTop;


    const message = values.message ? values.message.trim() : null;

    if (!message || !currentTransactionId) {
      return;
    }
    onSendMessage(currentTransactionId, message, config)
      .then(messageId => {
        form.reset();

        const newScrollHeight = messagesList.scrollHeight;
        const remainingHeight = newScrollHeight - messagesList.clientHeight;

        if (previousScrollTop + previousScrollHeight === remainingHeight || previousScrollTop === 0) {
          messagesList.scrollTo({
            top: newScrollHeight,
            behavior: 'smooth'
          });
        } else {
          messagesList.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight);
        }

        messagesList.scrollTop = newScrollHeight;
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  const scrollToMessage = (messageId) => {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        // block: 'start',
        behavior: 'smooth',
      });
    }
  }

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer isDark={true} currentPage="MessengerPage" />}
        footer={<Footer />}
      >

        <div className={css.headingContainer}>
          <img className={css.headingIcon} src={TitleIcon} />
          <H3 className={css.heading}>
            <FormattedMessage id="MessengerPage.title" />
          </H3>
        </div>

        <div className={css.mainContainer}>

          {fetchOrdersOrSalesError ? (
            <p className={css.error}>
              <FormattedMessage id="MessengerPage.fetchFailed" />
            </p>
          ) : null}

          <div className={css.horizontalWrapper}>
            <div className={css.leftContainer}>
              <ul className={css.itemList}>
                {!fetchInProgress ? (
                  <div>
                    <p className={css.title}>
                      <FormattedMessage id="MessengerPage.yourChats" />
                    </p>
                    {transactions.map(toChatItem)}
                  </div>
                ) : (
                  <li className={css.listItemsLoading}>
                    <IconSpinner />
                  </li>
                )}
                {hasNoResults ? (
                  <li key="noResults" className={css.noResults}>
                    <FormattedMessage id="MessengerPage.noChatsFound" />
                  </li>
                ) : null}
              </ul>
            </div>

            <div className={css.verticalLine} />

            <div className={css.messagesWrapper}>
              {fetchMessagesError ? (
                <p className={css.error}>
                  <FormattedMessage id="MessengerPage.fetchFailed" />
                </p>
              ) : null}

              <ul className={css.messagesList} ref={messagesListRef}>
                {!fetchMessagesInProgress ? (
                  messages.map(toTxMessageItem)
                ) : (
                  <li className={css.listItemsLoading}>
                    <IconSpinner />
                  </li>
                )}
                {hasNoMessage ? (
                  <li key="noResults" className={css.noResults}>
                    <FormattedMessage
                      id={'MessengerPage.noMessagesFound'}
                    />
                  </li>
                ) : null}
              </ul>

              <SendMessageForm
                formId='SendMessageForm'
                rootClassName={css.sendMessageForm}
                messagePlaceholder="Aa..."
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                // onFocus={this.onSendMessageFormFocus}
                // onBlur={this.onSendMessageFormBlur}
                onSubmit={onMessageSubmit}
              />

            </div>
          </div>

          {hasTransactions && pagination && pagination.totalPages > 1 ? (
            <PaginationLinks
              className={css.pagination}
              pageName="MessengerPage"
              pagePathParams={params}
              pagination={pagination}
            />
          ) : null}

        </div>
      </LayoutSingleColumn>
    </Page>
  );
};


MessengerPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    currentTransactionId,
    sendMessageInProgress,
    sendMessageError,
    messages, } = state.MessengerPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    fetchMessagesInProgress,
    currentTransactionId,
    fetchMessagesError,
    totalMessagePages,
    sendMessageInProgress,
    sendMessageError,
    oldestMessagePageFetched,
    messages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowMessages: (txId, page, config) => dispatch(fetchMessages(txId, page, config)),
    onShowMoreMessages: (txId, config) => dispatch(fetchMoreMessages(txId, config)),
    onSendMessage: (txId, message, config) => dispatch(sendMessage(txId, message, config)),
  };
};

const MessengerPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MessengerPageComponent);

export default MessengerPage;
