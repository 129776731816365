import React from 'react';

import css from './SectionSignUp.module.css';
import { FormattedMessage } from 'react-intl';
import { Button, NamedLink } from '../../../../components';

const SectionSignUp = props => {
  return (
    <div className={css.root}>
      <div className={css.semitransparentWrapper}>
        <p className={css.title}>
          <FormattedMessage id="SectionSignUp.title" />
        </p>

        <Button className={css.button}>
          <NamedLink name="SignupPage" className={css.signUp}>
            <FormattedMessage id="SectionSignUp.signUp" />
          </NamedLink>
        </Button>
      </div>
    </div>
  );
};

export default SectionSignUp;
