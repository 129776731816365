import React from 'react';

import css from './SectionHowItWorks.module.css';
import { FormattedMessage } from 'react-intl';
import browseIcon from './images/browse.png'
import doneIcon from './images/done.png'
import verifiedIcon from './images/verified.png'
import sellIcon from './images/sell.png'

const SectionHowItWorks = props => {

    return (
        <div className={css.root}>

            <p className={css.subtitle}>
                <FormattedMessage id="SectionHowItWorks.subtitle" />
            </p>
            <p className={css.title}>
                <FormattedMessage id="SectionHowItWorks.title" />
            </p>


            <div className={css.horizontalWrapper}>

                <div className={css.panelWrapper}>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionHowItWorks.professionals.title" />
                    </p>

                    <div className={css.line} />

                    <div className={css.horizontalWrapper}>
                        <div>

                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={verifiedIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.professionals.signup" />
                                </p>
                            </div>

                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={sellIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.professionals.sell" />
                                </p>
                            </div>

                        </div>

                        <div>
                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={browseIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.professionals.browse" />
                                </p>
                            </div>

                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={doneIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.professionals.getAppointed" />
                                </p>
                            </div>
                        </div>

                        <div>

                        </div>
                    </div>

                </div>

                <div className={css.panelWrapper}>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionHowItWorks.clients.title" />
                    </p>

                    <div className={css.line} />

                    <div className={css.horizontalWrapper}>
                        <div>

                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={verifiedIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.clients.signup" />
                                </p>
                            </div>

                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={sellIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.clients.post" />
                                </p>
                            </div>

                        </div>

                        <div>
                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={browseIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.clients.browse" />
                                </p>
                            </div>

                            <div className={css.verticalWrapper}>
                                <img className={css.icon} src={doneIcon} />
                                <p className={css.subtitle}>
                                    <FormattedMessage id="SectionHowItWorks.clients.hire" />
                                </p>
                            </div>
                        </div>

                        <div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SectionHowItWorks;
