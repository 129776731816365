import React from 'react';

import { FormattedMessage } from '../../../../util/reactIntl';

import { Button } from '../../../../components';
import css from '../../DashboardPage.module.css';
import { createResourceLocatorString } from '../../../../util/routes';
import { getUserStatus } from '../../../../util/user';

const LimitedDashboardConsultant = (props) => {

    const { history, routeConfiguration, currentUser, isPendingVerification } = props;

    return (
        <div className={css.roundedContainer}>

            {isPendingVerification ?
                <p className={css.info}>
                    <FormattedMessage id="DashboardPage.consultantInfo.pendingVerification" />
                </p>
                :
                <>
                    <p className={css.title}>
                        <FormattedMessage id="DashboardPage.consultantInfo.incompleteProfile.title" />
                    </p>
                    <p className={css.info}>
                        <FormattedMessage id="DashboardPage.consultantInfo.incompleteProfile" />
                    </p>
                </>
            }

            <div>
                <p className={css.statusLabel}>
                    <FormattedMessage id="DashboardPage.userProfileStatus" />
                </p>
                <p className={css.status}>
                    <FormattedMessage id={"profileStatuses." + getUserStatus(currentUser)} />
                </p>
            </div>

            <div className={css.circlesWrapper}>
                <div className={css.circle}>
                    1.
                </div>

                <div className={css.progressActive} />

                <div className={css.circle}>
                    2.
                </div>
                <div className={css.progress} />

                <div className={css.circle}>
                    3.
                </div>
                <div className={css.progress} />

                <div className={css.circle}>
                    4.
                </div>
            </div>

            <div className={css.stepsWrapper}>
                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.completeProfile" />
                </span>

                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.exploreOpportunities" />
                </span>

                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.bidOnGigs" />
                </span>

                <span className={css.step}>
                    <FormattedMessage id="DashboardPage.startAndCompleteGigs" />
                </span>
            </div>

            <div className={css.separator} />

            <div className={css.buttonWrapper}>

                <Button
                    className={css.button}
                    onClick={e => {
                        e.preventDefault();
                        history.push(createResourceLocatorString('ProfileSettingsPage', routeConfiguration, {}, {}));
                    }}
                >
                    <FormattedMessage id="DashboardPage.viewProfile" />
                </Button>
            </div>
        </div>
    )
}
export default LimitedDashboardConsultant