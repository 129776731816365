import { number } from 'prop-types';
import React from 'react';


const ProgressBar = props => {
  const {
    progress
  } = props;

  const backgroundStyle = {
    height: '6px',
    minWidth: '263px',
    borderRadius: '5px',
    backgroundColor: "#E2E8F0"
  }

  const progressStyle = {
    height: '100%',
    width: `${progress}%`,
    borderRadius: '5px',
    backgroundColor: '#CC9B0A'
  }

  return (
    <div style={backgroundStyle}>
      <div style={progressStyle} />
    </div>
  );
};

ProgressBar.defaultProps = {
  value: 0,
};

ProgressBar.propTypes = {
  value: number,
};

export default ProgressBar;